<script setup>
import {useElectionStore} from "@/stores/election";

const electionStore = useElectionStore();
</script>

<template>
    <nav>
        <div>
            <img src="../assets/logo.svg" alt="Spogliolive">
            <div v-if="electionStore.is_live">
                <p>LIVE</p>
            </div>
        </div>
    </nav>
</template>

<style scoped>

</style>
