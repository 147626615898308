<script setup>
    import {useElectionStore} from "@/stores/election";

    const electionStore = useElectionStore();
</script>

<template>
    <section class="overview">
        <header>
            <h3>{{ electionStore.election.name }}</h3>
            <p>{{ electionStore.election.city.name }}</p>
        </header>
        <img :src="electionStore.election.city.image" :alt="electionStore.election.city.name">
        <div class="infos">
            <div class="info">
                <i class="icon-user"></i>
                <div>
                    <p class="title">Sindaco in carica</p>
                    <p>{{ electionStore.election.city.current_major }}</p>
                </div>
            </div>
            <div class="info">
                <i class="icon-post"></i>
                <div>
                    <p class="title">Seggi totali</p>
                    <p>{{ electionStore.election.city.stations_number }}</p>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>

</style>
