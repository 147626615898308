<script setup>
import {computed, defineProps, onBeforeUnmount, reactive} from "vue";
import {useElectionStore} from "@/stores/election";
import axios from "axios";

const props = defineProps(['group', 'turn_id', 'status']);
const electionStore = useElectionStore();

const liveData = reactive({});
let liveInterval;
if (props.status === 'live'){
    axios.get(`turns/${props.turn_id}/groups/${props.group.id}/councilors/live`)
        .then(res => res.data)
        .then(res => liveData.data = res.data);

    liveInterval = setInterval(() => {
        axios.get(`turns/${props.turn_id}/groups/${props.group.id}/councilors/live`)
            .then(res => res.data)
            .then(res => liveData.data = res.data);
    }, 3000);
}

onBeforeUnmount(() => {
    clearInterval(liveInterval);
})

const most_voted = computed(() => {
    return electionStore.councilors[props.group.id].reduce((maxObj, currentObj) => {
        return currentObj.votes_count > maxObj.votes_count ? currentObj : maxObj;
    });
})

</script>

<template>
    <div class="councilors-container">
        <h5>{{ group.name }}</h5>
        <template v-if="electionStore.councilors[props.group.id] && electionStore.councilors[props.group.id].length">
            <div class="councilor"
                 v-for="councilor in electionStore.councilors[props.group.id].sort((a, b) => a.surname.localeCompare(b.surname))"
                 :key="councilor.id"
                 :class="{ greatest: most_voted.id === councilor.id && councilor.votes_count > 0 }"
            >
                <p>{{councilor.surname }} {{ councilor.name }}</p>
                <p v-if="props.status === 'live'">
                    <template v-if="councilor.id in liveData.data">
                        {{ liveData.data[councilor.id] }}
                    </template>
                    <template v-else>
                        0
                    </template>
                </p>
                <p v-else-if="props.status === 'passed'">{{ councilor.votes_count }}</p>
            </div>
        </template>
        <template v-else>
            <p>Non ci sono consiglieri per la lista selezionata</p>
        </template>
    </div>
</template>

<style scoped>

</style>
