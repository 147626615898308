<script setup>
import {computed, defineProps, onBeforeUnmount, reactive, ref} from 'vue';
import {useElectionStore} from "@/stores/election";
import CouncilorsContainer from "@/components/CouncilorsContainer.vue";
import axios from "axios";

const props = defineProps(['major_id', 'status', 'turn_id']);
const electionStore = useElectionStore();

const can_render = ref(false)
const selected_group = reactive({ value: null })

const group = computed(() => {
    if (selected_group.value === null) return null;
    return electionStore.groups[props.major_id][selected_group.value];
})

const liveData = reactive({});
let liveInterval;
onBeforeUnmount(() => clearInterval(liveInterval));

electionStore.loadGroups(props.major_id)
    .then(() => {
        if (electionStore.groups[props.major_id].length){
            changeGroup(0);
        }
        can_render.value = true

        if (props.status === 'live'){
            axios.get(`turns/${props.turn_id}/majors/${props.major_id}/groups/live`)
                .then(res => res.data)
                .then(res => liveData.data = res.data);
            liveInterval = setInterval(() => {
                axios.get(`turns/${props.turn_id}/majors/${props.major_id}/groups/live`)
                    .then(res => res.data)
                    .then(res => liveData.data = res.data);
            }, 3000);
        }
    });

function changeGroup(group_key){
    selected_group.value = group_key;
    electionStore.loadCouncilors(electionStore.groups[props.major_id][selected_group.value].id);
}

</script>

<template>
    <div class="groups-component">
        <template v-if="can_render">
            <template v-if="electionStore.groups[props.major_id].length">
                <div class="groups-container">
                    <div v-for="(group, key) in electionStore.groups[props.major_id]" :key="key" @click="changeGroup(key)" :class="{active: selected_group.value === key}">
                        <img :src="group.image" :alt="group.name">
                        <p v-if="props.status === 'live'">
                            <template v-if="liveData.data && group.id in liveData.data">
                                {{liveData.data[group.id]}}
                            </template>
                            <template v-else>
                                0
                            </template>
                        </p>
                        <p v-else-if="props.status === 'passed'">{{ group.votes_count }}</p>
                    </div>
                    <div v-if="liveData.data" class="accumulator">
                        TOTALE {{Object.values(liveData.data).reduce((acc, curr) => acc + parseInt(curr),0)}}
                    </div>
                    <div v-else-if="status === 'passed'" class="accumulator">
                        TOTALE {{Object.values(electionStore.groups[props.major_id]).reduce((acc, curr) => acc + parseInt(curr.votes_count),0)}}
                    </div>
                </div>
                <councilors-container v-if="group.value !== null" :group="group" :turn_id="props.turn_id" :status="props.status" />
            </template>
            <template v-else>
                Non ci sono liste collegate al candidato selezionato.
            </template>
        </template>
        <template v-else>
          Sto caricando i dati del sindaco selezionato..
        </template>
    </div>
</template>

<style scoped>

</style>
