<script setup>

</script>

<template>
  <footer>
      <div>
          <img src="../assets/logo_full.svg" alt="Spogliolive">
          <p>
              Spogliolive è realizzato da <a href="https://www.linkedin.com/in/fabrizio-romanelli-003464164/" target="_blank">Fabrizio</a>
              e <a href="https://www.linkedin.com/in/ilariolagravinese/" target="_blank">Ilario</a> e l'utilizzo è gratuito per tutti.
              I dati, le immagini e le informazioni riportate non sono di nostra proprietà e pertanto
              siamo esonerati da ogni responsabilità.
              <br/>
              <br/>
              Per segnalare eventuali anomalie o per contattarci, scrivi una mail
              a <a href="mailto:info@spogliolive.it">info@spogliolive.it</a>
          </p>
      </div>
  </footer>
</template>

<style scoped>

</style>
