import {defineStore} from 'pinia';
import axios from "axios";
import {Emitter} from 'vue-global-emitter'
import {watch} from "vue";

const em = new Emitter()
export const useElectionStore = defineStore('elections', {
    state: () => ({
        election: null,
        token: null,
        turns: null,
        is_live: false,
        live_turn_key: null,
        groups: {},
        councilors: {},
        banners: null,
        getting_banners: false
    }),
    actions: {
        getSubdomainToken() {
            var url = window.location.hostname;

            var regex = /^([a-zA-Z0-9-]+)\.([a-zA-Z0-9-]+)\.([a-zA-Z]{2,})$/;
            var match = url.match(regex);

            if (match != null)
                this.token = match[1];
            else
                this.token = 'acquaviva2023';
        },
        getElection() {
            if (!this.token){
                this.getSubdomainToken();
            }

            return axios.get(`/elections/slug/${this.token}`)
                .then(res => res.data)
                .then(res => {
                    this.election = res.data;
                    this.getTurns();
                    this.getBanners();
                });
        },
        getTurns() {
            return axios.get(`/elections/${this.election.id}/turns`)
                .then(res => res.data)
                .then(res => {
                    this.turns = res.data;

                    const now = new Date();

                    for (let turn_key in this.turns){
                        let turn = this.turns[turn_key];

                        let start_date = new Date(turn.started_at.replace(" ", "T"));
                        if (start_date > now) continue;

                        let end_date = new Date(turn.finished_at.replace(" ", "T"));
                        if (now > end_date) continue;

                        this.is_live = true;
                        this.live_turn_key = parseInt(turn_key);
                    }

                    em.emit('turns-loaded', res.data);
                });
        },
        getActualTurnKey() {
            if (this.live_turn_key) return this.live_turn_key;
            if (this.turns.length === 1) return 0;

            const now = new Date();

            for (let turn_key in this.turns){
                let turn = this.turns[turn_key];

                let end_date = new Date(turn.finished_at.replace(" ", "T"));
                if (now > end_date) continue;

                return turn_key;
            }

            return this.turns.length-1;
        },
        loadGroups(major_id) {
            return new Promise(resolve => {
                if (major_id in this.groups) return resolve(this.groups[major_id]);

                return axios.get(`/majors/${major_id}/groups`)
                    .then(res => res.data)
                    .then(res => resolve(this.groups[major_id] = res.data));
            })
        },
        loadCouncilors(group_id) {
            return new Promise(resolve => {
                if (group_id in this.councilors) return resolve(this.councilors[group_id]);

                return axios.get(`/groups/${group_id}/councilors`)
                    .then(res => res.data)
                    .then(res => resolve(this.councilors[group_id] = res.data));
            })
        },
        getBanners(){
            return new Promise(resolve => {
                if (this.banners) return resolve(this.banners);

                if (this.getting_banners){
                    let waiting_banners = watch(() => this.getting_banners, () => {
                        if (!this.getting_banners){
                            resolve(this.banners);
                            waiting_banners();
                        }
                    });
                    return;
                }

                this.getting_banners = true;
                return axios.get(`elections/${this.election.id}/banners/actives`)
                    .then(res => res.data)
                    .then(res => {
                        this.banners = res.data;
                        this.getting_banners = false;
                        return this.banners;
                    })
            });
        }
    }
});
