<template>
    <nav-bar />
    <main>
        <template v-if="loaded">
            <city-overview/>
            <banners-component v-if="electionStore.banners" :start_from="0" />
            <turns-container v-if="turns_loaded"/>
            <banners-component v-if="electionStore.banners" :start_from="Math.round(electionStore.banners.length / 2)" />
        </template>

        <template v-else>
            <p>Sto caricando le informazioni della tua città..</p>
        </template>
    </main>
    <footer-component />
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import {useElectionStore} from "@/stores/election";
import CityOverview from "@/components/CityOverview.vue";
import {Emitter} from "vue-global-emitter";
import TurnsContainer from "@/components/TurnsContainer.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import BannersComponent from "@/components/BannersComponent.vue";

export default {
    name: 'App',
    components: {
        BannersComponent,
        FooterComponent,
        TurnsContainer,
        CityOverview,
        NavBar,
    },
    data() {
        return {
            loaded: false,
            turns_loaded: false
        }
    },
    setup(){
      const electionStore = useElectionStore();

      return {
          electionStore
      }
    },
    beforeMount() {
        this.electionStore.getElection()
            .then(() => this.loaded = true);

        const em = new Emitter()
        em.listen("turns-loaded", () => this.turns_loaded = true);
    }
}
</script>

<style>

</style>
