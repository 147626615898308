<script setup>
import {defineProps, reactive, ref} from "vue";
import {useElectionStore} from "@/stores/election";

const electionStore = useElectionStore();
const props = defineProps(["start_from"]);
let banners = reactive({});
let active = ref(props.start_from);

electionStore.getBanners()
    .then(res => banners.values = res);

setInterval(() => {
    if (active.value === banners.values.length-1)
        active.value = 0
    else active.value++;
}, 5000)

</script>

<template>
  <section class="banners" v-if="banners.values">
      <template v-for="(banner, key) in banners.values" :key="key">
          <a :class="{ active: key === active}" :href="banner.url" target="_blank">
              <img :src="banner.image_desktop" alt="announcement">
          </a>
      </template>
  </section>
</template>

<style scoped>

</style>
