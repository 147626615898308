<script setup>
import {useElectionStore} from "@/stores/election";
import {ref} from "vue";
import TurnComponent from "@/components/TurnComponent.vue";

const electionStore = useElectionStore();

let selected_turn = ref(electionStore.getActualTurnKey())
</script>

<template>
    <section class="turns">
        <div class="turns-selector">
            <div v-for="(turn, key) in electionStore.turns"
                 :key="key"
                 :class="{ active : key === parseInt(selected_turn) }"
                 @click="selected_turn = key"
            >
                {{ turn.name }}
            </div>
        </div>
        <turn-component :key="selected_turn" :turn="electionStore.turns[selected_turn]"></turn-component>
    </section>
</template>

<style scoped>

</style>
