<script setup>
import {defineProps, onBeforeUnmount, onMounted, reactive, ref} from "vue";
import axios from "axios";

const props = defineProps(["turn"]);

const liveData = reactive({});
const closedVotingStations = reactive({});
let liveInterval;

onBeforeUnmount(() => clearInterval(liveInterval));

let voting_stations = reactive({});
let majors = reactive({});

axios.get(`turns/${props.turn.id}/voting_stations`)
    .then(res => res.data)
    .then(res => voting_stations.data = res.data);

axios.get(`turns/${props.turn.id}/voting_stations/live`)
    .then(res => res.data)
    .then(res => {
      liveData.data = res.data.voting_stations_data;
      closedVotingStations.data = res.data.closed_voting_stations;
    });

axios.get(`turns/${props.turn.id}/majors/live`)
    .then(res => res.data)
    .then(res => majors.data = res.data);

if (props.turn.status === 'live') {
  liveInterval = setInterval(() => {
    axios.get(`turns/${props.turn.id}/voting_stations/live`)
        .then(res => res.data)
        .then(res => {
          liveData.data = res.data.voting_stations_data;
          closedVotingStations.data = res.data.closed_voting_stations;
        });

    axios.get(`turns/${props.turn.id}/majors/live`)
        .then(res => res.data)
        .then(res => majors.data = res.data);
  }, 3000);
}

const container = ref(null);
const scrolled = ref(false)

onMounted(() => {
  container.value.addEventListener('scroll', function () {
    scrolled.value = container.value.scrollLeft > 0;
  });
})

function getTotalOfStation(id) {
  return Object.values(liveData.data[id]).reduce((acc, curr) => acc + curr, 0) - liveData.data[id]["blank"] - liveData.data[id]["null"] - liveData.data[id]["id"] - liveData.data[id]["voters"];
}

function getTotal() {
  if (!majors.data) return 0;
  return Object.values(majors.data).reduce((acc, curr) => parseInt(acc) + parseInt(curr.votes), 0);
}

function getTotalVoters() {
  if (!liveData.data) return 0;
  return Object.values(liveData.data).reduce((acc, curr) => parseInt(acc) + parseInt(curr["voters"] || 0), 0);
}

function getLast() {
  let lasting = getTotalVoters() - getTotal() - getTotalNull() - getTotalBlank();
  return lasting > 0 ? lasting : 0;
}

function getTotalNull() {
  if (!liveData.data) return 0;
  return Object.values(liveData.data).reduce((acc, curr) => parseInt(acc) + parseInt(curr["null"]), 0);
}

function getTotalBlank() {
  if (!liveData.data) return 0;
  return Object.values(liveData.data).reduce((acc, curr) => parseInt(acc) + parseInt(curr["blank"]), 0);
}

function getTotalBlankPercent() {
  if (getTotalBlank() === 0) return 0;
  return Math.round(100 * getTotalBlank() / getTotalVoters() * 100) / 100;
}

function getTotalNullPercent() {
  if (getTotalNull() === 0) return 0;
  return Math.round(100 * getTotalNull() / getTotalVoters() * 100) / 100;
}

function getTotalValidPercent() {
  let total_valid = getTotal();
  let total_voters = getTotalVoters();
  if (total_valid === 0 || total_voters === 0) return 0;
  if (total_valid > total_voters) return 100;
  return Math.round(100 * getTotal() / getTotalVoters() * 100) / 100;
}

function getLastPercent() {
  if (getTotal() === 0) return 0;
  return Math.round(100 * getLast() / getTotal() * 100) / 100;
}

function getLastOfStation(id){
  let last = liveData.data[id]["voters"] - getTotalOfStation(id) - liveData.data[id]["blank"] - liveData.data[id]["null"];

  return last > 0 ? last : 0;
}

</script>

<template>
  <section class="voting_station_grid" ref="container">
    <table :class="{live: props.turn.status === 'live'}">
      <tr>
        <th :class="{scrolled: scrolled}">Sez</th>
        <th v-for="(major, key) in props.turn.majors" :key="key">
          <img :src="major.image" :alt="major.surname">
        </th>
        <th>B</th>
        <th>N</th>
        <th>Validi</th>
        <th>Votanti</th>
        <th v-if="props.turn.status === 'live'">Rimanenti</th>
      </tr>
      <tr>
        <td :class="{scrolled: scrolled}" class="title">TOTALE</td>
        <td v-for="(major, key) in props.turn.majors" :key="key">
          <template v-if="majors.data && majors.data[major.id]"><b>{{ majors.data[major.id].votes }}</b></template>
          <template v-else><b>0</b></template>
        </td>
        <td>{{ getTotalBlank() }}</td>
        <td>{{ getTotalNull() }}</td>
        <td>
          <template v-if="majors.data">
            <b>{{ getTotal() }}</b>
          </template>
        </td>
        <td>{{ getTotalVoters() }}</td>
        <td v-if="props.turn.status === 'live'">
          <template v-if="liveData.data && majors.data">
            {{ getLast() }}
          </template>
          <template v-else>
            --
          </template>
        </td>
      </tr>
      <tr>
        <td :class="{scrolled: scrolled}" class="title">%</td>
        <td v-for="(major, key) in props.turn.majors" :key="key">
          <template v-if="majors.data && majors.data[major.id]"><b>{{ majors.data[major.id].percent }}%</b></template>
          <template v-else><b>0%</b></template>
        </td>
        <td>{{ getTotalBlankPercent() }}%</td>
        <td>{{ getTotalNullPercent() }}%</td>
        <td>{{ getTotalValidPercent() }}%</td>
        <td>--</td>
        <td v-if="props.turn.status === 'live'">{{ getLastPercent() }}%</td>
      </tr>
      <tr v-for="(voting_station, key) in voting_stations.data" :key="key" :data-key="voting_station.id"
          :class="{closed: closedVotingStations.data.includes(voting_station.id)}">
        <td :class="{scrolled: scrolled}" class="title">{{ voting_station.station.name }}</td>
        <td v-for="(major, key) in props.turn.majors"
            :key="key"
        >{{ liveData.data[voting_station.id][major.id] }}
        </td>
        <td>{{ liveData.data[voting_station.id]["blank"] }}</td>
        <td>{{ liveData.data[voting_station.id]["null"] }}</td>
        <td>{{ getTotalOfStation(voting_station.id) }}</td>
        <td>{{ liveData.data[voting_station.id]["voters"] || "--" }}</td>
        <td v-if="props.turn.status === 'live'">
          {{ getLastOfStation(voting_station.id) }}
        </td>
      </tr>
    </table>
  </section>
</template>

<style scoped>

</style>
