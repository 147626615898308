import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import axios from 'axios';
import "./style/app.scss";
import {useElectionStore} from "@/stores/election";

const pinia = createPinia()

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

createApp(App)
    .use(pinia)
    .mount('#app')

useElectionStore().getSubdomainToken();
