<script setup>
import {defineProps, onBeforeUnmount, reactive, ref, watch} from "vue";
import MajorRow from "@/components/MajorRow.vue";
import axios from "axios";
import VotingStationGrid from "@/components/VotingStationGrid.vue";
import {useElectionStore} from "@/stores/election";

const electionStore = useElectionStore();

const props = defineProps(['turn'])

let active_major = ref(null);

const majors = ref([]);

function majorClick(key) {
    if (active_major.value !== key) {
        active_major.value = key;

        if (active_major.value.id in electionStore.groups){
            console.log("has");
            let rect = majors.value[key].$el.getBoundingClientRect();
            window.scrollTo({
                top: window.scrollY + rect.top - 60,
                left: 0,
                behavior: 'smooth'
            })
        } else {
            console.log("doesnt")
            let watcher = watch(() => electionStore.groups, () => {
                console.log("got it");
                let rect = majors.value[key].$el.getBoundingClientRect();
                window.scrollTo({
                    top: window.scrollY + rect.top - 60,
                    left: 0,
                    behavior: 'smooth'
                })
                watcher();
            }, {deep: true});
        }
        return;
    }

    active_major.value = null;
}

const liveData = reactive({});
let liveTimer;
onBeforeUnmount(() => clearInterval(liveTimer));

if (props.turn.status !== 'programmed'){
    axios.get(`/turns/${props.turn.id}/majors/live`)
        .then(res => res.data)
        .then(res => {
            liveData.data = res.data
        });

    if (props.turn.status === 'live'){
        liveTimer = setInterval(() => {
            axios.get(`/turns/${props.turn.id}/majors/live`)
                .then(res => res.data)
                .then(res => {
                    liveData.data = res.data
                });
        }, 3000)

    }
}

const grid = ref(false);

</script>

<template>
    <div class="turn-content">
        <major-row v-for="(major, key) in props.turn.majors"
                   :key="key"
                   :value="major"
                   :active="active_major  === key"
                   :majors_only="props.turn.majors_only"
                   :status="props.turn.status"
                   :turn_id="props.turn.id"
                   :live="props.turn.status !== 'programmed' && liveData.data ? liveData.data[major.id] : null"
                   @selected="majorClick(key)"
                   ref="majors"
        ></major-row>
        <div class="grid-container" v-if="props.turn.status !== 'programmed'">
            <div class="grid-activator" @click="grid = !grid">
                Visualizza lo spoglio per sezione
            </div>
            <div class="grid-content" v-if="grid">
                <voting-station-grid :turn="props.turn"/>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
